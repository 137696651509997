:host {
    --finder-me-slide-btn-color: var(--finder-primary-active);
    --finder-me-slide-btn-hover-color: var(--finder-primary-hover);
    --finder-me-slide-btn-focus-color: var(--finder-primary-focus);
    --finder-me-slide-btn-active-color: var(--finder-primary-active);
}

// Necessary ME overrides
wdpr-media-engine,
finder-media-gallery {
    &.disabled-fullscreen {
        pointer-events: none;
    }

    .me {
        .me-title-bar {
            // Hide the title on the ME
            // We add this title so not sure why we cannot just NOT add it
            // But that doesn't seem to work, so...
            .me-title {
                display: none;
            }
        }

        .me-custom-html-thumbnails-append {
            // Shows All<amountOfThumbnails> link on ME
            display: inline;

            .me-fs-btn {
                --finder-primary-action: #FFF;
                line-height: 44px;
                vertical-align: top;
            }
        }
    }

    .me .me-controls .me-controls-video .me-video-play-pause {
        border: var(--finder-me-me-video-play-pause-border, none);
    }

    .me-btn.me-active, .me.me-no-touch .me-btn:hover, .me.me-no-touch .me-btn:focus {
        border: var(--finder-me-me-video-play-pause-border-hover, none);
        border-radius: var(--finder-me-me-video-play-pause-border-radius-hover, 0);
        padding: var(--finder-me-me-video-play-pause-padding-hover, 0);
        color: var(--finder-me-me-video-play-pause-color-hover, 0);
    }
}

.me-slides-controls > .me-btn {
    color: var(--finder-me-slide-btn-color);
}

.me-slides-controls > .me-btn:hover {
    color: var(--finder-me-slide-btn-hover-color);
}

.me-slides-controls > .me-btn:focus {
    color: var(--finder-me-slide-btn-focus-color);
}

.me-slides-controls > .me-btn:active {
    color: var(--finder-me-slide-btn-active-color);
}

body.overview.fullscreen {
    .finder-header {
        display: none;
    }
}

// Ensure header/footer is hidden when ME is full screen
body.me-scroll-lock {
    .finder-header,
    .finder-footer {
        display: none;
    }
}

.captioned-media-gallery {
    .me {
        border-radius: var(--captioned-media-gallery-border-radius-gallery, unset);
        margin-bottom: var(--captioned-media-gallery-margin-bottom-gallery, 20px);
    }
}

.tutorial {
    .me {
        .me-thumbnails {
            display: var(--tutorial-thumbnails-display, none);
        }

        // Make the controls layer the full size of the video
        .me-controls {
            position: absolute;
            height: 100%;
            inset: 0;
            flex-direction: row;

            // The video controls should sit along the bottom
            // They can auto-hide on idle, so we set opacity and trasition
            .me-controls-video {
                position: absolute;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                bottom: 0;
                opacity: 1;
                background-color: rgba(19, 37, 59, 0.7);
                transition: all 250ms ease-in;
                z-index: 5; // This places controls "above" the "click to play" video

                // Flexbox lets us center the playback controls easily
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 10px;
                align-items: center;
                margin: unset;

                // Reset control positioning from ME defaults
                .me-video-play-pause,
                .me-video-progress-bar,
                .me-video-timer,
                .me-video-volume,
                .me-video-cc {
                    position: relative;
                    display: inline-block;
                    inset: unset;
                    margin: 0;
                    vertical-align: middle;
                }

                // Constrain the width of the progress bar
                .me-video-progress-bar {
                    max-width: 200px;
                }

                // The fullscreen button needs icon content (uses hack)
                .me-fullscreen::before {
                    display: inline-block;
                    font-size: 21px;
                    content: '\e337';
                }
            }
        }

        &.me-idle {
            .me-controls {
                .me-controls-video {
                    bottom: -50px;
                    opacity: 0;
                }
            }
        }

        &.me-fullscreen {
            .me-controls {
                height: 50px;
                inset: unset 0 0 0;

                .me-fullscreen,
                .me-controls-general {
                    display: none;
                }
            }
        }
    }
}

body.fullscreen {
    .fullscreen-constrained {
        wdpr-media-engine,
        finder-media-gallery {
            .me.me-fullscreen {
                .me-slides {
                    .me-slides-media {
                        ul {
                            li {
                                video {
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
